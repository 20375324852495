import { InsightCard } from "@/components/atoms";
import { Box, Grid, Typography, Stack, Tooltip, Card, CardContent } from "@mui/material";
import { PenPortrait as PenPortraitType, Question } from "@/types";
import React, { useEffect, useState } from "react";

import { PenPortraitMap } from "@/components/molecules";
import {toPercentage, toColor, toIndex, toSize} from "@/utils/common";
import { NumericFormat } from "react-number-format";

interface PenPortraitProps {
  data: PenPortraitType;
  mapStyle: string;
}

const toStart = (v: string) => {
  switch (v) {
    case 'Age Band': return '18-24';
    case 'Income': return '£20k';
    case 'Lifestage': return 'Young';
    case 'Social Grade': return 'DE';
    case 'House Price': return '£150k';
    case 'Socio Economic Levels': return '10';
    case 'Monthly Expenditure': return '£50';
    default: return ''
  }
}

const toEnd = (v: string) => {
  switch (v) {
    case 'Age Band': return '65+';
    case 'Income': return '£100k+';
    case 'Lifestage': return 'Retired';
    case 'Social Grade': return 'AB';
    case 'House Price': return '£800k+';
    case 'Socio Economic Levels': return '1';
    case 'Monthly Expenditure': return '£500+';
    default: return ''
  }
}

const PenPortrait: React.FC<PenPortraitProps> = ({
  data,
  mapStyle,
}) => {
  const [questions, setQuestions] = useState<Array<Question>>([]);

  useEffect(() => {
    setQuestions(
      data.questions.slice(0, 6).map((question) => ({
        id: question.id,
        question: question.question,
      }))
    );
  }, [data]);

  const handleQuestionChange = (newQuestion: string, questionIndex: number) => {
    const question = data.questions.find(
      (question) => question.question === newQuestion
    );
    if (question) {
      setQuestions((prev) => {
        const newQuestions = [...prev];
        newQuestions[questionIndex] = {
          id: question.id,
          question: question.question,
        };
        return newQuestions;
      });
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      style={{
        marginTop: 10,
      }}
    >
      <Grid container item xs={9}>
        <Grid
          container
          item
          xs={12}
          style={{
            borderTop: "1px solid #e0e0e0",
            borderBottom: "1px solid #e0e0e0",
            marginRight: 12,
          }}
        >
          {["Age Band", "Income", "Lifestage", "Social Grade", "House Price", "Monthly Expenditure"].map((demographic, index) => {
            let values = data.demographics_all.filter(x => x.tag_category == demographic)
            demographic === 'Social Grade' && values.reverse()
            const width = Math.min(values.map(x => toSize(x.score)).reduce((a, b) => a + b, 0) + (8 * values.length) + 12, 245)
            const maxVal = Math.max(...values.map(x => x.score))

            const sizes = values.map(x => (x.score / maxVal) * 20)
            return (
              <Grid item xs={4} key={index}>
              <Box padding={2}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold" }}
                  color="primary"
                >
                  {demographic.charAt(0).toUpperCase() +
                  demographic.slice(1)}
                </Typography>
                <Box display="flex" flexDirection="row" justifyContent="space-between" pt={1} width={width}>
                  <Typography variant="caption" >{toStart(demographic)}</Typography>
                  <Typography variant="caption" >{toEnd(demographic)}</Typography>
                </Box>
                <Stack direction="row" spacing={1} pl={1} alignItems="center" sx={{ height: 45 }}>
                  {values.map((row, idx) => (
                    <Tooltip title={`${row.tag_name} (${toPercentage(row.rate)}) (${toIndex(row.score)})`}>
                      <Stack>
                        {/*<Typography variant="caption" sx={{ fontSize: '8px' }}>{row.tag_name}</Typography>*/}
                        {/*<Box key={`box-${idx}`} sx={{height: (row.score) * 15, width: (row.score) * 15, borderRadius: '50%', backgroundColor: toColor(row.score) }}></Box>*/}
                        <Box key={`box-${idx}`} sx={{height: toSize(row.score), width: toSize(row.score), borderRadius: '50%', backgroundColor: toColor(row.score) }}></Box>
                      </Stack>
                    </Tooltip>
                  ))}
                </Stack>
              </Box>
            </Grid>
          )})}
          {["postcodes", "households", "population"].map((sizeType, index) => (
            <Grid item xs={4} key={index}>
              <Box padding={2}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold" }}
                  color="primary"
                >
                  {sizeType.charAt(0).toUpperCase() + sizeType.slice(1)}
                </Typography>
                <Typography variant="body1">
                  <NumericFormat
                    value={data.size[sizeType]}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <div className="small-map-area">
          <PenPortraitMap
            areaScores={data.location.areaScores}
            mapStyle={mapStyle}
          />
        </div>
      </Grid>
      {data.description && (
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{"&:last-child": { pb: 2 }}}>
              <Typography
                variant="body2"
                // style={{ marginTop: 12, marginRight: 12 }}
              >
                {data.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid container item xs={12} spacing={2}>
        {questions.map((question, index) => {
          const penPortraitInsight = data.insight.find(
            (insight) => insight.question === question.question
          );
          if (!penPortraitInsight) return null;

          return (
            <Grid item xs={4} key={index}>
              <InsightCard
                question={question}
                selectedQuestions={questions}
                questions={data.questions}
                variables={penPortraitInsight.variables}
                group={penPortraitInsight.tag_group}
                type={penPortraitInsight.tag_type}
                onQuestionChange={(question) =>
                  handleQuestionChange(question, index)
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default PenPortrait;
