import { InsightVariablesModal } from "@/components/atoms";
import {causeIcon, interestIcon} from "@/constants";
import {toIndex} from "@/utils/common";
import { PenPortraitInsightVariable, Question } from "@/types";
import { SvgToComponent } from "@/utils/assets";
import { ChevronRight } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Select,
  SelectChangeEvent,
  Tooltip,
  SvgIcon,
  Typography,
  Stack
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

interface InsightCardProps {
  question: Question;
  selectedQuestions: Array<Question>;
  questions: Array<Question>;
  variables: Array<PenPortraitInsightVariable>;
  group: string;
  type: string;
  onQuestionChange: (newQuestion: string) => void;
}

const InsightCard: React.FC<InsightCardProps> = ({
  question,
  selectedQuestions,
  questions,
  variables,
  group,
  type,
  onQuestionChange,
}) => {
  const [openInsightModal, setOpenInsightModal] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    const selectedQuestion = questions.find(
      (question) => question.question === selectedValue
    );
    if (selectedQuestion) onQuestionChange(selectedQuestion.question);
  };

  const renderInsights = (
    group: string,
    variable: PenPortraitInsightVariable
  ) => {
    if (
      group === "Interests" ||
      (group === "Entertainment")
    ) {
      return (
        <Tooltip title={`${variable.tag_name} (${toIndex(variable.score)})`}>
          <Stack spacing={1} display="flex" flexDirection="column" width="100%" textAlign="center">
            <SvgIcon
              component={interestIcon(variable.tag_name).ReactComponent}
              inheritViewBox
              sx={{ width: '100%', height: '90%', aspectRatio: '1/1'}}
            />
            <Typography
              variant="caption"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
            >
              {variable.tag_name}
            </Typography>
          </Stack>
        </Tooltip>
      );
    }
    else if (group === "Causes") {
      return (
        <Tooltip title={`${variable.tag_name} (${toIndex(variable.score)})`}>
          <Stack spacing={1} display="flex" flexDirection="column" width="100%" textAlign="center">
            <SvgIcon
              component={causeIcon(variable.tag_name).ReactComponent}
              inheritViewBox
              sx={{ width: '100%', height: '90%', aspectRatio: '1/1'}}
            />
            <Typography
              variant="caption"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
            >
              {variable.tag_name}
            </Typography>
          </Stack>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        key={variable.tag_key}
        title={`${variable.tag_name} (${toIndex(variable.score)})`}
      >
        <Stack spacing={1} display="flex" flexDirection="column" width="100%" textAlign="center">
          <img
            src={variable.image}
            alt={variable.tag_name}
            style={{
              maxWidth: "100%",
              height: "100%",
              borderRadius: 5,
              display: "block",
            }}
          />
          <Typography
            variant="caption"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {variable.tag_name}
          </Typography>
        </Stack>
      </Tooltip>
    );
  };

  const handleInsightVariablesModalClose = () => setOpenInsightModal(false);

  return (
    <Card sx={{ minHeight: '138px' }}>
      <CardContent sx={{ pb: 0 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Select
            value={question.question}
            onChange={handleChange}
            variant="outlined"
            color="primary"
            style={{ marginBottom: 16, height: '36px' }}
          >
            {questions.map((data) => (
              <MenuItem
                key={data.question}
                value={data.question}
                disabled={selectedQuestions.some(
                  (selectedQuestion) => selectedQuestion.id === data.id
                )}
              >
                {data.question}
              </MenuItem>
            ))}
          </Select>
          <Grid container justifyContent={"space-between"} spacing={2}>
            {variables.slice(0, 6).map((variable) => (
              <Grid item xs={2} key={variable.tag_key}>
                {renderInsights(group, variable)}
              </Grid>
            ))}{" "}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InsightCard;
